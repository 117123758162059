<template>
  <v-container fluid class="pr-0 pl-0">
    <v-card>
      <v-card-text class="pa-0 elevation-0">
        <v-toolbar flat color="transparent">
          <v-icon class="grey--text mr-3">mdi-account-cash-outline</v-icon>
          <h2 class="heading font-weight-normal grey--text">{{ $t('billing.title') }}</h2>
        </v-toolbar>
        <v-divider class="mx-5"></v-divider>
        <v-row class="px-4 pt-4">
          <v-col xs="12" md="4">
            <span class="subtitle-2">{{ $t('billing.fields.dateRange') }}</span>
            <v-select
              class="mb-3"
              v-model="state.filters.dateRangeSelected"
              :items="billingLists.DATE_RANGE"
              @change="actions.applyDateFilter($event)"
              prepend-inner-icon="mdi-cursor-pointer"
              solo
              chips
              dense
              flat
              solo-inverted
              hide-details
            >
              <template v-slot:selection="data">
                <v-chip small label v-bind="data.attrs">
                  {{ data.item }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col xs="12" md="4">
            <span class="subtitle-2">{{ $t('billing.fields.documentType') }}</span>
            <v-autocomplete
              class="mb-3"
              v-model="state.filters.documentType"
              :items="documentTypeList()"
              item-value="_id"
              item-text="name"
              v-bind:label="$t('billing.fields.documentType')"
              prepend-inner-icon="mdi-cursor-pointer"
              multiple
              solo
              chips
              dense
              flat
              solo-inverted
              hide-details
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  label
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="removeFilter(data.item, 'documentType')"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col xs="12" md="4">
            <span class="subtitle-2">{{ $t('billing.fields.invoiceStatus') }}</span>
            <v-autocomplete
              class="mb-3"
              v-model="state.filters.invoiceStatus"
              :items="billingLists.INVOICE_STATUS"
              v-bind:label="$t('billing.fields.invoiceStatus')"
              prepend-inner-icon="mdi-cursor-pointer"
              multiple
              solo
              chips
              dense
              flat
              solo-inverted
              hide-details
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  label
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="removeFilter(data.item, 'invoiceStatus')"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="px-8 py-2">
          <v-btn @click="applyFilters()" depressed>
            <v-icon class="mr-1">{{ Icons.send }}</v-icon>
            <span class="text-capitalize">Apply Filters</span>
          </v-btn>
        </v-row>
        <v-row class="px-8 py-2">
          <data-table
            ref="dataTable"
            :headers="headers"
            :items="state.items"
            :loading="state.loading"
            :server-items-length="state.total"
            @paginate="actions.applyOptions($event)"
          >
            <template v-slot:item.paymentMethodValid="{ item }">
              <v-tooltip v-if="!item.paymentMethodValid" top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="yellow darken-3">mdi-alert</v-icon>
                </template>
                <span>Payment method is not valid</span>
              </v-tooltip>
            </template>
            <template v-slot:item.invoiceNumber="{ item }">
              <v-chip label small>{{ item.invoiceNumber }}</v-chip>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | date }}
            </template>
            <template v-slot:item._id="{ item }">
              {{ item._id }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.type.replace('_', ' ') }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                small
                label
                class="ma-1"
                :color="
                  { PENDING: 'grey', UNPAID: 'orange', INVOICED: 'green', PAID: 'green' }[
                    item.status
                  ]
                "
                dark
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.startDate="{ item }">
              {{ item.startDate | date }}
            </template>
            <template v-slot:item.endDate="{ item }">
              {{ item.endDate | date }}
            </template>
            <template v-slot:item.amount="{ item }">
              <td style="text-align: right">
                <span v-if="item.currency === 'USD'">
                  <span style="float: left">$ </span>
                  {{ item.amountUSD.toFixed(2) }}
                </span>
                <span v-else>
                  <span style="float: left">€ </span>
                  {{ item.amountEUR.toFixed(2) }}
                </span>
              </td>
            </template>
            <template v-slot:item.action="{ item }">
              <div class="action-buttons">
                <v-btn
                  v-bind:href="item.documentUrl"
                  target="_blank"
                  style="text-decoration: none"
                  small
                  depressed
                >
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
                <v-btn
                  @click="downloadPDF(item.documentUrl)"
                  style="text-decoration: none"
                  small
                  depressed
                >
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
              </div>
            </template>
          </data-table>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { inject, onMounted, onUnmounted } from '@vue/composition-api';
import billingLists from '../../store/constants/BillingLists';
import DataTable from '../Components/DataTable';

export default {
  name: 'affiliate-billing',
  components: {
    DataTable,
  },
  setup(props, context) {
    const application = inject('Application');
    const billing = inject('Billing');
    onMounted(async () => {
      billing.state._affiliates = application.state.user._id;
    });
    onUnmounted(async () => {
      await billing.actions.resetInitialValues();
    });
    const headers = [
      {
        text: '',
        aling: 'center',
        value: 'paymentMethodValid',
        sortable: false,
        width: 10,
      },
      {
        text: context.root.$t('billing.fields.documentNumber'),
        aling: 'center',
        value: 'invoiceNumber',
        sortable: true,
        width: 200,
      },
      {
        text: context.root.$t('billing.fields.createdAt'),
        aling: 'center',
        value: 'createdAt',
        sortable: true,
        width: 150,
      },
      {
        text: context.root.$t('billing.fields.documentType'),
        aling: 'center',
        value: 'type',
        sortable: true,
        width: 150,
      },
      {
        text: context.root.$t('billing.fields.documentStatus'),
        aling: 'center',
        value: 'status',
        sortable: true,
        width: 150,
      },
      {
        text: context.root.$t('billing.fields.startDate'),
        aling: 'center',
        value: 'startDate',
        sortable: true,
        width: 150,
      },
      {
        text: context.root.$t('billing.fields.endDate'),
        aling: 'center',
        value: 'endDate',
        sortable: true,
        width: 150,
      },
      {
        text: context.root.$t('billing.fields.amount'),
        aling: 'center',
        value: 'amount',
        sortable: true,
        width: 150,
      },
      {
        text: context.root.$t('billing.fields.actions'),
        value: 'action',
        aling: 'center',
        sortable: false,
        width: 150,
      },
    ];
    const removeFilter = (item, type) => {
      const index = billing.state.filters[type].indexOf(
        item.hasOwnProperty('_id') ? item._id : item
      );
      if (index >= 0) {
        billing.state.filters[type].splice(index, 1);

        if (!billing.state.filters[type].length) {
          delete billing.state.filters[type];
        }
      }
    };
    return { application, ...billing, billing, headers, billingLists, removeFilter };
  },
  methods: {
    documentTypeList() {
      return billingLists.DOCUMENT_TYPE.map((el) => (el = { _id: el, name: el.replace('_', ' ') }));
    },
    async downloadPDF(url) {
      const CORS_PROXY = 'https://corsproxy.io/?';
      const content = await fetch(CORS_PROXY + url).then((response) => response.blob());
      const file = new Blob([content], { type: 'application/pdf' });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.setAttribute('download', url.split('/').pop());
      document.body.appendChild(a);
      a.dispatchEvent(new MouseEvent('click'));
    },
    async applyFilters() {
      const pageReset = this.$refs.dataTable.resetPage();

      if (!pageReset) {
        await this.billing.actions.loadItems();
      }
    },
  },
};
</script>
